<template>
  <v-card elevation="1" tile class="pa-5">
    <v-row>
      <v-col cols="12" md="6" class="subtitle-1 font-weight-bold mb-5">
        INFORME RECLAMOS DE ACUERDO CON SU TIPOLOGÍA
      </v-col>
      <v-col cols="12" md="6" class="text-right">
        <v-radio-group
          v-model="groupBySearch"
          row
          label="Agrupación por fecha de:"
          class="mt-0 mb-0"
        >
          <v-radio label="creación" :value="false"></v-radio>
          <v-radio label="ultima modificación" :value="true"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-card-text>
      <v-form v-model="form.isValid" @submit.prevent="search">
        <v-row>
          <v-col cols="12" md="2"
            ><v-select
              label="Fecha desde"
              outlined
              dense
              v-model="dateSince"
              :items="dateSelect"
            >
            </v-select
          ></v-col>
          <v-col cols="12" md="2">
            <v-select
              label="Fecha hasta"
              outlined
              dense
              v-model="dateUntil"
              :items="dateSelect"
            >
            </v-select>
          </v-col>
          <v-col cols="12" md="3">
            <v-select
              label="Banco"
              outlined
              dense
              v-model="bank"
              :items="
                banks.map((bank) => {
                  return { value: bank.code, text: bank.name };
                })
              "
            >
            </v-select>
          </v-col>
          <v-col cols="12" md="2">
            <v-btn
              class="mx-2"
              fab
              small
              dark
              color="blue lighten-3"
              @click="clean"
              :loading="processing"
            >
              <v-icon>
                mdi-eraser
              </v-icon>
            </v-btn>
            <v-btn
              type="submit"
              class="mx-2"
              fab
              small
              color="primary"
              :loading="processing"
              :disabled="!form.isValid || processing"
            >
              <v-icon dark>
                mdi-magnify
              </v-icon>
            </v-btn>
            <ImportExcel
              :disabled="dataComputed.length == 0"
              :loading="processing"
              :data="dataComputedExcel"
              :json_fields="headersComputedExcel"
              name="Informe-Reclamos-Acuerdo-Tipología-"
            />
          </v-col>
        </v-row>
      </v-form>

      <v-row v-if="dataComputed.length > 0">
        <v-col cols="12" sm="12" md="9" xl="7">
          <v-data-table
            :headers="headersComputed"
            :items="dataComputed"
            :items-per-page="1000"
            hide-default-footer
            dense
            show-expand
            item-key="typology"
            :single-expand="true"
            :expanded.sync="expanded"
          >
            <template v-slot:[`item.typology`]="{ item }">
              {{ getTypologyName(item.typology) }}
            </template>

            <template v-slot:[`item.v2025`]="{ item }">
              {{ formatNumber(item.v2025) }}
            </template>
            <template v-slot:[`item.t2025`]="{ item }">
              {{ formatNumber(item.t2025) }}
            </template>

            <template v-slot:[`item.v2024`]="{ item }">
              {{ formatNumber(item.v2024) }}
            </template>
            <template v-slot:[`item.t2024`]="{ item }">
              {{ formatNumber(item.t2024) }}
            </template>

            <template v-slot:[`item.v2023`]="{ item }">
              {{ formatNumber(item.v2023) }}
            </template>
            <template v-slot:[`item.t2023`]="{ item }">
              {{ formatNumber(item.t2023) }}
            </template>

            <template v-slot:[`item.v2022`]="{ item }">
              {{ formatNumber(item.v2022) }}
            </template>
            <template v-slot:[`item.t2022`]="{ item }">
              {{ formatNumber(item.t2022) }}
            </template>

            <template v-slot:[`item.v2021`]="{ item }">
              {{ formatNumber(item.v2021) }}
            </template>
            <template v-slot:[`item.t2021`]="{ item }">
              {{ formatNumber(item.t2021) }}
            </template>

            <template v-slot:[`item.v2020`]="{ item }">
              {{ formatNumber(item.v2020) }}
            </template>
            <template v-slot:[`item.t2020`]="{ item }">
              {{ formatNumber(item.t2020) }}
            </template>

            <template v-slot:[`item.v2019`]="{ item }">
              {{ formatNumber(item.v2019) }}
            </template>
            <template v-slot:[`item.t2019`]="{ item }">
              {{ formatNumber(item.t2019) }}
            </template>

            <template slot="body.append">
              <tr>
                <td v-for="header in headersComputed" :key="header.value">
                  {{ sumarizeNew(header) }}
                </td>
              </tr>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-card class="ma-2" elevation="3" color="purple lighten-5">
                  <v-card-title
                    >{{ getTypologyName(item.typology) }}
                    <v-spacer />
                    <ImportExcel
                      :disabled="dataBankTypologyExcel(item).length == 0"
                      :loading="processing"
                      :data="dataBankTypologyExcel(item)"
                      :json_fields="headersBankExcel"
                      :name="
                        `Informe-Reclamos-Acuerdo-Tipología-${getTypologyName(
                          item.typology
                        )}-`
                      "
                    />
                  </v-card-title>
                  <v-data-table
                    :headers="headersBank"
                    :items="dataBankTypology(item)"
                    :items-per-page="1000"
                    item-key="bankCode"
                    show-expand
                    :single-expand="true"
                    :expanded.sync="expandedBank"
                    hide-default-footer
                    dense
                    class="purple lighten-5"
                  >
                    <template v-slot:[`item.bankCode`]="{ item }">
                      {{ getBankName(item.bankCode) }}
                    </template>

                    <template v-slot:[`item.v2025`]="{ item }">
                      {{ formatNumber(item.v2025) }}
                    </template>
                    <template v-slot:[`item.t2025`]="{ item }">
                      {{ formatNumber(item.t2025) }}
                    </template>

                    <template v-slot:[`item.v2024`]="{ item }">
                      {{ formatNumber(item.v2024) }}
                    </template>
                    <template v-slot:[`item.t2024`]="{ item }">
                      {{ formatNumber(item.t2024) }}
                    </template>

                    <template v-slot:[`item.v2023`]="{ item }">
                      {{ formatNumber(item.v2023) }}
                    </template>
                    <template v-slot:[`item.t2023`]="{ item }">
                      {{ formatNumber(item.t2023) }}
                    </template>

                    <template v-slot:[`item.v2022`]="{ item }">
                      {{ formatNumber(item.v2022) }}
                    </template>
                    <template v-slot:[`item.t2022`]="{ item }">
                      {{ formatNumber(item.t2022) }}
                    </template>

                    <template v-slot:[`item.v2021`]="{ item }">
                      {{ formatNumber(item.v2021) }}
                    </template>
                    <template v-slot:[`item.t2021`]="{ item }">
                      {{ formatNumber(item.t2021) }}
                    </template>

                    <template v-slot:[`item.v2020`]="{ item }">
                      {{ formatNumber(item.v2020) }}
                    </template>
                    <template v-slot:[`item.t2020`]="{ item }">
                      {{ formatNumber(item.t2020) }}
                    </template>

                    <template v-slot:[`item.v2019`]="{ item }">
                      {{ formatNumber(item.v2019) }}
                    </template>
                    <template v-slot:[`item.t2019`]="{ item }">
                      {{ formatNumber(item.t2019) }}
                    </template>

                    <template v-slot:expanded-item="{ headers, item }">
                      <td :colspan="headers.length">
                        <v-card
                          class="ma-2"
                          elevation="3"
                          color="indigo lighten-5"
                        >
                          <v-card-title
                            >{{ getBankName(item.bankCode) }}
                            <v-spacer />
                            <ImportExcel
                              :disabled="
                                dataDetailsBankTypologyExcel(item).length == 0
                              "
                              :loading="processing"
                              :data="dataDetailsBankTypologyExcel(item)"
                              :json_fields="headersBankDetailsExcel"
                              :name="
                                `Informe-Reclamos-Acuerdo-Tipología-${getTypologyName(
                                  item.typology
                                )}-${getBankName(item.bankCode)}-`
                              "
                            />
                          </v-card-title>
                          <v-data-table
                            :headers="headersBankDetails"
                            :items="dataDetailsBankTypology(item)"
                            :items-per-page="1000"
                            item-key="month"
                            hide-default-footer
                            dense
                            class="indigo lighten-5"
                          >
                            <template v-slot:[`item.month`]="{ item }">
                              {{ getMonth(item.month) }}
                            </template>

                            <template v-slot:[`item.v2025`]="{ item }">{{
                              formatNumber(item.v2025)
                            }}</template>
                            <template v-slot:[`item.t2025`]="{ item }">{{
                              formatNumber(item.t2025)
                            }}</template>

                            <template v-slot:[`item.v2024`]="{ item }">
                              {{ formatNumber(item.v2024) }}
                            </template>
                            <template v-slot:[`item.t2024`]="{ item }">
                              {{ formatNumber(item.t2024) }}
                            </template>

                            <template v-slot:[`item.v2023`]="{ item }">
                              {{ formatNumber(item.v2023) }}
                            </template>
                            <template v-slot:[`item.t2023`]="{ item }">
                              {{ formatNumber(item.t2023) }}
                            </template>

                            <template v-slot:[`item.v2022`]="{ item }">
                              {{ formatNumber(item.v2022) }}
                            </template>
                            <template v-slot:[`item.t2022`]="{ item }">
                              {{ formatNumber(item.t2022) }}
                            </template>

                            <template v-slot:[`item.v2021`]="{ item }">
                              {{ formatNumber(item.v2021) }}
                            </template>
                            <template v-slot:[`item.t2021`]="{ item }">
                              {{ formatNumber(item.t2021) }}
                            </template>

                            <template v-slot:[`item.v2020`]="{ item }">
                              {{ formatNumber(item.v2020) }}
                            </template>
                            <template v-slot:[`item.t2020`]="{ item }">
                              {{ formatNumber(item.t2020) }}
                            </template>

                            <template v-slot:[`item.v2019`]="{ item }">
                              {{ formatNumber(item.v2019) }}
                            </template>
                            <template v-slot:[`item.t2019`]="{ item }">
                              {{ formatNumber(item.t2019) }}
                            </template>
                          </v-data-table>
                        </v-card>
                      </td>
                    </template>
                  </v-data-table>
                </v-card>
              </td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row v-if="dataComputed.length > 0">
        <v-col cols="12" md="12">
          <canvas height="100" id="claims-graph"></canvas>
        </v-col>
      </v-row>
    </v-card-text>

    <Processing :viewProcessing="processing" title="Cargando..." />
    <Alert
      :dialog="alert.show"
      :msj="alert.message"
      @close="alert.show = false"
    />
  </v-card>
</template>

<script>
import reportService from "@/services/report";
import baseViewMixin from "@/mixins/baseView";
import parameters from "@/mixins/parameters";
import report from "@/mixins/report";

import Alert from "@/components/Alert";
import Processing from "@/components/Processing";
import ImportExcel from "@/components/ImportExcel";

export default {
  mixins: [baseViewMixin, parameters, report],
  components: {
    Processing,
    ImportExcel,
    Alert,
  },
  methods: {
    search() {
      this.result = [];

      if (this.dateSince != "" && this.dateUntil != "") {
        if (this.dateSince > this.dateUntil) {
          this.alert.message =
            "La fecha desde no puede ser mayor a la fecha hasta";
          this.alert.show = true;
          return;
        }
      }

      this.processing = true;

      reportService
        .getTypologiesAll(this.groupBySearch)
        .then((result) => {
          this.result = result.data;

          this.processing = false;

          if (this.result.length == 0) {
            this.alert.message = "No hay registros";
            this.alert.show = true;
            return;
          }

          if (this.result.length > 0) {
            setTimeout(
              () =>
                this.createChart("claims-graph", "bar", this.dataComputedGraph),
              1500
            );
          }
        })
        .catch((err) => {
          this.alert.message = err;
          this.alert.show = true;
          this.processing = false;
        });
    },
    sumarize(pos) {
      let result = 0;
      for (let index = 0; index < this.dataComputed.length; index++) {
        const element = this.dataComputed[index];
        const convert = Object.keys(element).map((key) => [key, element[key]]);
        let value = 0;
        try {
          value = convert[pos][1];
        } catch {
          value = 0;
        }
        result = result + value;
      }
      return this.$n(result);
    },
    sumarizeNew(header) {
      if (header.value == "typology") return "Total";
      if (header.text == "") return "";

      let result = 0;
      this.dataComputed.forEach((d) => {
        let value = 0;
        try {
          value = d[header.value];
        } catch {
          value = 0;
        }
        if (value != undefined || value != null) result = result + value;
      });

      return this.$n(result);
    },
    dataBankTypology(item) {
      const yearNow = new Date().getFullYear().toString();
      const result = this.groupBy(this.result, "typology"); //agrupa por una atributo, como resultado agrupa y el otro valor son todos los registros
      const convert = Object.keys(result).map((key) => [key, result[key]]); //ya que no se sabe los atributos, se convierte a una sub arreglo
      const search = convert.find((c) => c[0] == item.typology); //filtro el agrupado

      const groupBank = this.groupBy(search[1], "bankCode"); //agrupo por banco el resultado de la agrupacion de estado
      const convertBank = Object.keys(groupBank).map((key) => [
        key,
        groupBank[key],
      ]); //convierto los bancos a un subarreglo

      let resultFinal = [];
      for (let index = 0; index < convertBank.length; index++) {
        let bank = convertBank[index];

        const resultGroupBank = this.groupBy(bank[1], "year");
        const convertResultGroupBank = Object.keys(
          resultGroupBank
        ).map((key) => [key, resultGroupBank[key]]);

        let bankFinal = {
          bankCode: bank[0],
          typology: item.typology,
          "data-table-expand": 0,
        };

        //filter
        for (let a = 0; a < convertResultGroupBank.length; a++) {
          const sumOf = convertResultGroupBank[a][1].reduce((sum, detail) => {
            const validationFilter = this.filter(detail);
            if (validationFilter) return sum + detail.value;

            return sum;
          }, 0);

          bankFinal[`v${convertResultGroupBank[a][0]}`] = sumOf;
        }

        //total
        for (let a = 0; a < convertResultGroupBank.length; a++) {
          const sumOf = convertResultGroupBank[a][1].reduce((sum, detail) => {
            return sum + detail.value;
          }, 0);

          if (yearNow != convertResultGroupBank[a][0])
            bankFinal[`t${convertResultGroupBank[a][0]}`] = sumOf;
        }

        this.validateBankFinal(bankFinal);
        if (this.bank != "") {
          if (bankFinal.bankCode == this.bank) resultFinal.push(bankFinal);
        } else {
          resultFinal.push(bankFinal);
        }
      }

      return resultFinal;
    },
    dataBankTypologyExcel(item) {
      let data = JSON.parse(JSON.stringify(this.dataBankTypology(item)));
      for (let i = 0; i < data.length; i++) {
        data[i].bankCode = this.getBankName(data[i].bankCode);
      }

      return data;
    },
    dataDetailsBankTypology(item) {
      const yearNow = new Date().getFullYear().toString();
      const result = this.groupBy(this.result, "typology"); //agrupa por una atributo, como resultado agrupa y el otro valor son todos los registros
      const convert = Object.keys(result).map((key) => [key, result[key]]); //ya que no se sabe los atributos, se convierte a una sub arreglo
      const search = convert.find((c) => c[0] == item.typology); //filtro el agrupado

      const groupBank = this.groupBy(search[1], "bankCode"); //agrupo por banco el resultado de la agrupacion de estado
      const convertBank = Object.keys(groupBank).map((key) => [
        key,
        groupBank[key],
      ]); //convierto los bancos a un subarreglo

      const searchBank = convertBank.find((b) => b[0] == item.bankCode);
      if (searchBank == undefined || searchBank[1].length == 0) return [];

      const groupMonthBank = this.groupBy(searchBank[1], "month"); //agrupo por mes

      const convertMonth = Object.keys(groupMonthBank).map((key) => [
        key,
        groupMonthBank[key],
      ]); //convierto los bancos a un subarreglo

      let resultFinal = [];

      for (let index = 0; index < convertMonth.length; index++) {
        let month = convertMonth[index];

        const resultGroupMonth = this.groupBy(month[1], "year");
        const convertResultGroupMonth = Object.keys(
          resultGroupMonth
        ).map((key) => [key, resultGroupMonth[key]]);

        let monthFinal = {
          bankCode: item.bankCode,
          stateCode: item.stateCode,
          month: month[0],
          "data-table-expand": 0,
        };

        //filter
        for (let a = 0; a < convertResultGroupMonth.length; a++) {
          const sumOf = convertResultGroupMonth[a][1].reduce((sum, detail) => {
            const validationFilter = this.filter(detail);
            if (validationFilter) return sum + detail.value;

            return sum;
          }, 0);

          monthFinal[`v${convertResultGroupMonth[a][0]}`] = sumOf;
        }

        //total
        for (let b = 0; b < convertResultGroupMonth.length; b++) {
          const sumOf = convertResultGroupMonth[b][1].reduce((sum, detail) => {
            return sum + detail.value;
          }, 0);

          if (yearNow != convertResultGroupMonth[b][0])
            monthFinal[`t${convertResultGroupMonth[b][0]}`] = sumOf;
        }

        this.validateBankDetailFinal(monthFinal);

        if (this.dateSince == "" && this.dateUntil == "") {
          resultFinal.push(monthFinal);
        }

        if (this.dateSince != "" && this.dateUntil == "") {
          if (this.dateSince <= parseInt(monthFinal.month))
            resultFinal.push(monthFinal);
        }

        if (this.dateSince == "" && this.dateUntil != "") {
          if (this.dateUntil >= parseInt(monthFinal.month))
            resultFinal.push(monthFinal);
        }

        if (this.dateSince != "" && this.dateUntil != "") {
          if (
            this.dateSince <= parseInt(monthFinal.month) &&
            this.dateUntil >= parseInt(monthFinal.month)
          ) {
            resultFinal.push(monthFinal);
          }
        }
      }

      return resultFinal;
    },
    dataDetailsBankTypologyExcel(item) {
      let data = JSON.parse(JSON.stringify(this.dataDetailsBankTypology(item)));
      for (let i = 0; i < data.length; i++) {
        data[i].month = this.getMonth(data[i].month);
      }

      return data;
    },
    validateTypologyFinal(typology) {
      const convert = Object.keys(typology).map((key) => [key, typology[key]]);
      if (convert.length < 6) {
        this.headersDefault.forEach((h) => {
          if (typology[h.value] == undefined || typology[h.value] == null)
            typology[h.value] = 0;
        });
      }
    },
    validateBankFinal(bank) {
      const convert = Object.keys(bank).map((key) => [key, bank[key]]);
      if (convert.length < 7) {
        this.headersBank.forEach((h) => {
          if (bank[h.value] == undefined || bank[h.value] == null)
            bank[h.value] = 0;
        });
      }
    },
    validateBankDetailFinal(month) {
      const convert = Object.keys(month).map((key) => [key, month[key]]);
      if (convert.length < 8) {
        this.headersBankDetails.forEach((h) => {
          if (month[h.value] == undefined || month[h.value] == null)
            month[h.value] = 0;
        });
      }
    },
  },
  computed: {
    headersComputed() {
      return this.headersDefault;
    },
    headersDefault() {
      let resultFinal = [];

      resultFinal.push({
        text: "Tipología",
        value: "typology",
        sortable: false,
      });
      const yearNow = new Date().getFullYear().toString();

      resultFinal.push({
        text: `${yearNow}`,
        value: `v${yearNow}`,
        sortable: true,
      });
      resultFinal.push({
        text: `${yearNow - 1}`,
        value: `v${yearNow - 1}`,
        sortable: true,
      });
      resultFinal.push({
        text: `${yearNow - 2}`,
        value: `v${yearNow - 2}`,
        sortable: true,
      });
      resultFinal.push({
        text: `${yearNow - 3}`,
        value: `v${yearNow - 3}`,
        sortable: true,
      });

      resultFinal.push({
        text: `Total ${yearNow - 1}`,
        value: `t${yearNow - 1}`,
        sortable: true,
      });
      resultFinal.push({
        text: `Total ${yearNow - 2}`,
        value: `t${yearNow - 2}`,
        sortable: true,
      });
      resultFinal.push({
        text: `Total ${yearNow - 3}`,
        value: `t${yearNow - 3}`,
        sortable: true,
      });

      resultFinal.push({ text: "", value: "data-table-expand" });

      return resultFinal;
    },
    headersComputedExcel() {
      let result = {};

      if (this.headersComputed.length > 0) {
        for (let index = 0; index < this.headersComputed.length; index++) {
          if (this.headersComputed[index].text != "") {
            result[
              `.${this.headersComputed[index].text}`
            ] = this.headersComputed[index].value;
          }
        }
      }

      return result;
    },
    headersBank() {
      let headers = JSON.parse(JSON.stringify(this.headersComputed));

      headers[0].text = "Banco";
      headers[0].value = "bankCode";

      return headers;
    },
    headersBankExcel() {
      let headers = JSON.parse(JSON.stringify(this.headersComputed));

      headers[0].text = "Banco";
      headers[0].value = "bankCode";

      let result = {};
      for (let index = 0; index < headers.length; index++) {
        if (headers[index].text != "") {
          result[`.${headers[index].text}`] = headers[index].value;
        }
      }

      return result;
    },
    headersBankDetails() {
      let headers = JSON.parse(JSON.stringify(this.headersComputed));

      headers[0].text = "Mes";
      headers[0].value = "month";

      headers.pop();

      return headers;
    },
    headersBankDetailsExcel() {
      let headers = JSON.parse(JSON.stringify(this.headersComputed));

      headers[0].text = "Mes";
      headers[0].value = "month";

      headers.pop();

      let result = {};
      for (let index = 0; index < headers.length; index++) {
        if (headers[index].text != "") {
          result[`.${headers[index].text}`] = headers[index].value;
        }
      }

      return result;
    },
    dataComputed() {
      const yearNow = new Date().getFullYear().toString();

      const result = this.groupBy(this.result, "typology"); //agrupa por una atrinuto, como resultado agrupa y el otro valor son todos los registros
      const convert = Object.keys(result).map((key) => [key, result[key]]); //ya que no se sabe los atributos, se convierte a una sub arreglo ************ con este truco se puede trabajar datos dinamicamente

      let resultFinal = [];
      for (let index = 0; index < convert.length; index++) {
        //recorrer el resultado por estado y volver agrupar por años
        let typology = convert[index];

        const resultGroupState = this.groupBy(typology[1], "year");
        const convertResultGroupState = Object.keys(
          resultGroupState
        ).map((key) => [key, resultGroupState[key]]);

        let stateFinal = {
          typology: typology[0],
          "data-table-expand": 0,
        };

        //filter
        const convertResultGroupStateReverse = convertResultGroupState.reverse();
        for (let a = 0; a < convertResultGroupStateReverse.length; a++) {
          const sumOf = convertResultGroupStateReverse[a][1].reduce(
            (sum, detail) => {
              const validationFilter = this.filter(detail);
              if (validationFilter) return sum + detail.value;

              return sum;
            },
            0
          );

          stateFinal[`v${convertResultGroupStateReverse[a][0]}`] = sumOf;
        }

        //total
        for (let a = 0; a < convertResultGroupStateReverse.length; a++) {
          const sumOf = convertResultGroupStateReverse[a][1].reduce(
            (sum, detail) => {
              return sum + detail.value;
            },
            0
          );

          if (yearNow != convertResultGroupStateReverse[a][0])
            stateFinal[`t${convertResultGroupStateReverse[a][0]}`] = sumOf;
        }

        this.validateTypologyFinal(stateFinal);
        resultFinal.push(stateFinal);
      }

      return resultFinal;
    },
    dataComputedExcel() {
      let data = JSON.parse(JSON.stringify(this.dataComputed));
      for (let i = 0; i < data.length; i++) {
        data[i].typology = this.getTypologyName(data[i].typology);
      }

      return data;
    },
    dataComputedGraph() {
      const yearNow = new Date().getFullYear().toString();
      let etiquetas = [];
      let datasets = [];

      let años = [];

      años = [
        [
          yearNow,
          `v${yearNow}`,
          "rgba(54, 162, 235, 0.2)",
          "rgba(54, 162, 235, 1)",
        ],
        [
          yearNow - 1,
          `v${yearNow - 1}`,
          "rgba(255, 159, 64, 0.2)",
          "rgba(255, 159, 64, 1)",
        ],
        [
          yearNow - 2,
          `v${yearNow - 2}`,
          "rgba(0, 152, 70, 0.2)",
          "rgba(0, 152, 70, 1)",
        ],
        [
          yearNow - 3,
          `v${yearNow - 3}`,
          "rgba(245, 46, 10, 0.2)",
          "rgba(245, 46, 10, 1)",
        ],
      ];

      for (let a = 0; a < años.length; a++) {
        let dataAño = [];
        for (let index = 0; index < this.dataComputed.length; index++) {
          const element = this.dataComputed[index];

          const findEtiqueta = etiquetas.find(
            (e) => e == this.getTypologyName(element.typology)
          );
          if (findEtiqueta == null)
            etiquetas.push(this.getTypologyName(element.typology));

          const convert = Object.keys(element).map((key) => [
            key,
            element[key],
          ]);
          const position = años[a][1];
          try {
            for (let c = 0; c < convert.length; c++) {
              if (convert[c][0] == position) {
                dataAño.push(convert[c][1]);
              }
            }
          } catch (error) {
            //console.log(error);
          }
        }

        const datasetAño = {
          label: años[a][0],
          data: dataAño, // La data es un arreglo que debe tener la misma cantidad de valores que la cantidad de etiquetas
          backgroundColor: años[a][2], // Color de fondo
          borderColor: años[a][3], // Color del borde
          borderWidth: 1, // Ancho del borde
        };

        datasets.push(datasetAño);
      }

      let result = {
        labels: etiquetas,
        datasets: datasets,
      };

      return result;
    },
  },
};
</script>

<style lang="scss">
.v-data-table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: none;
}
</style>
